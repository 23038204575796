import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import { useSelector } from 'react-redux';
 
 
const TotalRevenue = lazy(() => import('../Dashboard-reports/Totalrevenue/TotalRevenue'));
const PermitReimbursementRec = lazy(() => import('../Dashboard-reports/PermitReimbursement/PermitReimbursementrec'));
const PermitReimbursementByid = lazy(() => import('../Dashboard-reports/PermitReimbursement/permitreimbursementid'));
const Records = lazy(() => import('../Pages/Records/Records'));
const View = lazy(() => import('../Pages/View Data/View'));
const ListInvoice = lazy(() => import('../Pages/Invoice/ListInvoice'));
const AddInvoice = lazy(() => import('../Pages/Invoice/AddInvoice'));
const ImportExcel = lazy(() => import('../Pages/Import Excel/ImportExcel'));
const ViewArchive = lazy(() => import('../Pages/View Archive/ViewArchive'));
const AddContractor = lazy(() => import('../Pages/Contractor/AddContractor'));
const ListContractor = lazy(() => import('../Pages/Contractor/ListContractor'));
const AddItem = lazy(() => import('../Pages/Item/AddItem'));
const ListItem = lazy(() => import('../Pages/Item/ListItem'));
const AddStatus = lazy(() => import('../Pages/Status/AddStatus'));
const ListStatus = lazy(() => import('../Pages/Status/ListStatus'));
const AddUser = lazy(() => import('../Pages/User Management/AddUser'));
const UserList = lazy(() => import('../Pages/User Management/UserList'));
const AddRowModal = lazy(() => import('../Pages/View Data/AddRowModal'));
const AddTaskForm = lazy(() => import('../Pages/View Data/AddTaskForm'));
const Reports = lazy(() => import('../Pages/Reports/Reports'));
const EmailSetting = lazy(() => import('../Pages/Smtp Page/EmailSetting'));
const Exception = lazy(() => import('../Pages/Exception/Exception'));
const CutInfo = lazy(() => import('../Pages/View Data/CutInfo'));
const Crew = lazy(() => import('../Pages/Crew/Crew'));
const Abc = lazy(() => import('../Pages/Abc/Abc'));
const WgException =lazy(()=>import(('../Pages/WgException/WgException')))
const Emergencywork = lazy(() => import('../Pages/Emergency Work/Emergencywork'));
const OccupancyPermitPayment = lazy(() => import('../Pages/OccupancyPermit/OccupancyPermitPayment'));
const Assigning = lazy(() => import('../Pages/Assigning CUID/Assigning'));
const Scheduling = lazy(() => import('../Pages/Scheduling CUID/Scheduling'));
const Permit = lazy(() => import('../Pages/Permit/Permit'));
const RevenueStatus = lazy(() => import('../Pages/Revenuestatus/RevenueStatus'));
const PermitStatus  = lazy(() => import('../Pages/Permit Status/PermitSatus'));
const OccupancyPermitStatusDetails = lazy(() => import('../Pages/Permit Status/PermitStatusDetails'));

// Dashboard Reports Routes Below
 
const CompletedTask = lazy(
  () => import('../Dashboard-reports/Completed work order/CompletedWorkOrder')
);
const NoPermit = lazy(() => import('../Dashboard-reports/No Permit/NoPermit'));
const PendingApproval = lazy(() => import('../Dashboard-reports/Pending approval/PendingApproval'));
const PermitExpire = lazy(() => import('../Dashboard-reports/Permit Expire/PermitExpire'));
const CuSubmission = lazy(() => import('../Dashboard-reports/Cu For Submission/CuForSubmission'));
const PendingInvoice = lazy(() => import('../Dashboard-reports/Pending invoice/PendingInvoice'));
const TotalTask = lazy(() => import('../Dashboard-reports/Total work order/TotalWorkOrders'));
const RejectedCuId = lazy(() => import('../Dashboard-reports/Rejected CU ID/RejectedCuId'));
const ExceptionRecords = lazy(() => import('../Dashboard-reports/Exceptions/Exception'));
const ComplaintWr = lazy(() => import('../Dashboard-reports/Complaint wr/Complaint'));
const PriorityWr = lazy(() => import('../Dashboard-reports/Priority wrNo/Priority'));
const ConstructionPermitExpire = lazy(() => import('../Dashboard-reports/Construction Permit Expire/ConstructionPermitExpire'));
const OccupancyPermitExpire = lazy(() => import('../Dashboard-reports/Occupancy Permit Expire/OccupancyPermitExpire'));
const OccupancyPermitStatus = lazy(() => import('../Dashboard-reports/Occupancy Permit Status/OccupancyPermitStatus'));
const LiquidAsphalt = lazy(() => import('../Dashboard-reports/Liquid Asphalt/Common'));
const KpiReport = lazy(() => import('../Dashboard-reports/KPI Report/Kpi'));
 
const CustomRoutes = () => {
  const user = useSelector((state) => state.auth);
  return (
    <Suspense fallback={<LoadingSpinner />}>
      {user.roleid !== 2 ? (
        <Routes>
          <Route exact path="/Records" element={<Records />} />
          <Route exact path="/View" element={<View />} />
          <Route exact path="/Assigning" element={<Assigning />} />
          <Route exact path="/permit-status" element={<PermitStatus />} />
          <Route exact path="/permit-status-details" element={<OccupancyPermitStatusDetails />} />
          <Route exact path="/Scheduling" element={<Scheduling />} />
          <Route exact path="/totalrevenue" element={<TotalRevenue/>} />
          <Route exact path="/permitreimbursement" element={<OccupancyPermitPayment/>}/>
          <Route exact path='permitreimbursementrecord' element={<PermitReimbursementRec/>}/>
          <Route exact path='permitreimbursementrecord/:id' element={<PermitReimbursementByid/>}/>
          <Route exact path="/Emergencywork" element={<Emergencywork />} />
          <Route exact path="/ListInvoice" element={<ListInvoice />} />
          <Route exact path="/AddInvoice" element={<AddInvoice />} />
          <Route exact path="/EditInvoice/:invoiceId" element={<AddInvoice />} />
          <Route exact path="/ImportExcel" element={<ImportExcel />} />
          <Route exact path="/ViewArchive" element={<ViewArchive />} />
          <Route exact path="/AddContractor" element={<AddContractor />} />
          <Route exact path="/EditContractor/:ContractorId" element={<AddContractor />} />
          <Route exact path="/ListContractor" element={<ListContractor />} />
          <Route exact path="/permit" element={<Permit />} />
          <Route exact path="/AddItem" element={<AddItem />} />
          <Route exact path="/EditItem/:itemId" element={<AddItem />} />
          <Route exact path="/ListItem" element={<ListItem />} />
          <Route exact path="/AddStatus" element={<AddStatus />} />
          <Route exact path="/EditStatus/:statusId" element={<AddStatus />} />
          <Route exact path="/revenuestatus" element={<RevenueStatus/>} />
          <Route exact path="/ListStatus" element={<ListStatus />} />
          <Route exact path="/AddUser" element={<AddUser />} />
          <Route exact path="/EditUser/:userId" element={<AddUser />} />
          <Route exact path="/addrow" element={<AddRowModal />} />
          <Route exact path="/EditRow/:rowId" element={<AddRowModal />} />
          <Route exact path="/addTask/:rowId" element={<AddTaskForm />} />
          <Route exact path="/UserList" element={<UserList />} />
          <Route exact path="/Reports" element={<Reports />} />
          <Route exact path="/emailSetting" element={<EmailSetting />} />
          <Route exact path="/Exception" element={<Exception />} />
          <Route exact path="/cutInfo/:rowId" element={<CutInfo />} />
          <Route exact path="/Crew" element={<Crew />} />
          <Route exact path="/CompletedWorkOrder" element={<CompletedTask />} />
          <Route exact path="/NoPermit" element={<NoPermit />} />
          <Route exact path="/PendingApproval" element={<PendingApproval />} />
          <Route exact path="/PermitExpire" element={<PermitExpire />} />
          <Route exact path="/PendingInvoice" element={<PendingInvoice />} />
          <Route exact path="/CuSubmission" element={<CuSubmission />} />
          <Route exact path="/RejectedCuid" element={<RejectedCuId />} />
          <Route exact path="/ExceptionRecord" element={<ExceptionRecords />} />
          <Route exact path="/ComplaintRecord" element={<ComplaintWr />} />
          <Route exact path="/PriorityRecord" element={<PriorityWr />} />
          <Route exact path="/Liquid-Asphalt" element={<LiquidAsphalt />} />
          <Route exact path="/kpi-Report" element={<KpiReport />} />
          <Route exact path="/TotalWorkOrder" element={<TotalTask />} />
          <Route exact path="/ConstructionPermitExpireRecords" element={<ConstructionPermitExpire />} />
          <Route exact path="/OccupancyPermitExpireRecords" element={<OccupancyPermitExpire />} />
          <Route exact path="/OccupancyPermitStatus" element={<OccupancyPermitStatus />} />
        </Routes>
      ) : (
        <Routes>
          <Route exact path="/Abc" element={<Abc />} />
          <Route exact path="/WgException" element={<WgException />} />
        </Routes>
      )}
    </Suspense>
  );
};
 
export default CustomRoutes;